import { createContext, useContext } from "react";
import { Organization } from "../models/shared/Organization";
import { Spinner } from "react-bootstrap";
import { useFirebaseDoc } from "../hooks/firebaseHooks";
import { COLLECTIONS, ORGANIZATION_ID } from "../utils/shared/constants";

const organizationContext = createContext<Organization>({ goodsOwners: [], name: '', emailRecipients: [] });

export const useOrganization = () => useContext(organizationContext);

interface Props {
    children: React.ReactNode;
}

export const OrganizationProvider = (props: Props) => {
    const [organization] = useFirebaseDoc<Organization>(`${COLLECTIONS.ORGANIZATIONS}/${ORGANIZATION_ID}`);

    if (!organization) {
        return <Spinner animation='border' />
    }

    return (
        <organizationContext.Provider value={organization}>
            {props.children}
        </organizationContext.Provider>
    )
}