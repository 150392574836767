import React from 'react'
import { GoodsOwner } from '../models/shared/GoodsOwner'
import { OngoingInvoice } from '../models/shared/OngoingInvoice'
import { DataTable } from './DataTable'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import InvoiceFinalizeAction from './InvoiceFinalizeAction';

interface Props {
    goodsOwner: GoodsOwner,
    invoices: OngoingInvoice[],
}


function InvoiceTable(props: Props) {
    const sortedInvoices = props.invoices.sort((a, b) => {
        return a.invoiceInfo.invoiceDate.localeCompare(b.invoiceInfo.invoiceDate);
    });

    const groupedPerMonth: Record<string, number> = {};
    for (const invoice of sortedInvoices) {
        const invoiceDate = new Date(invoice.invoiceInfo.invoiceDate);
        const inoviceMonthKey = invoiceDate.toLocaleString('default', { month: 'short', year: 'numeric' });;

        if (!groupedPerMonth[inoviceMonthKey]) {
            groupedPerMonth[inoviceMonthKey] = 0;
        }
        groupedPerMonth[inoviceMonthKey] += invoice.invoiceInfo.invoiceAmount;
    }


    const orderedMonthKeys = [];
    if (sortedInvoices.length > 0) {
        let currentDate = new Date(sortedInvoices[0].invoiceInfo.invoiceDate);
        currentDate.setDate(1);
        while (currentDate <= new Date()) {
            orderedMonthKeys.push(currentDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
    }

    const graphData = orderedMonthKeys.map(month => ({
        month,
        amount: groupedPerMonth[month] || 0
    }));



    return <>
        <h4>{props.goodsOwner.name}</h4>

        <ResponsiveContainer width="100%" height={100}>
            <AreaChart
                data={graphData}
            >
                <XAxis dataKey="month" />
                <Tooltip />
                <Area type="monotone" dataKey="amount" name='Invoice amounts' />

            </AreaChart>
        </ResponsiveContainer>

        <DataTable data={sortedInvoices} columns={[
            {
                label: 'Invoice',
                render: (invoice: OngoingInvoice) => <>{invoice.invoiceInfo.title}</>,

            },
            {
                label: 'Amount',
                render: (invoice: OngoingInvoice) => <>{invoice.invoiceInfo.invoiceAmount}</>,

            },
            {
                label: 'Invoice Date',
                render: (invoice: OngoingInvoice) => <>{invoice.invoiceInfo.invoiceDate}</>,

            },
            {
                label: 'Finalize',
                render: (invoice: OngoingInvoice) => <InvoiceFinalizeAction invoice={invoice} />,
            },
        ]} />
    </>
}


export default InvoiceTable
