import React, { FC } from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'

interface IProps {
    processing?: boolean
    icon?: string
}

export const AdvancedButton: FC<IProps & ButtonProps> = ({ children, processing, disabled, icon, ...rest }) => {
    let prefix = null;
    const spacerClass = children ? 'me-2' : '';

    if (processing) {
        prefix = <Spinner size='sm' className={spacerClass} />
    } else if (icon) {
        prefix = <i className={`bi ${icon} ${spacerClass}`} />
    }

    return (
        <Button {...rest} disabled={processing || disabled} style={{ minHeight: 38 }} >
            <div className='center'>{prefix} {children}</div>
        </Button>
    )
}
