const config = {
    useEmulators: !!process.env.REACT_APP_EMULATORS,
    functions: {
        updateInvoices: 'https://updateinvoices-dnu54v6ana-ew.a.run.app',
        finalizeInvoice: 'https://finalizeinvoice-dnu54v6ana-ew.a.run.app',
    }
}

if (config.useEmulators) {
    config.functions = {
        updateInvoices: 'http://127.0.0.1:5001/logilink-b67ce/europe-west1/updateInvoices',
        finalizeInvoice: 'http://127.0.0.1:5001/logilink-b67ce/europe-west1/finalizeInvoice',
    }
}

export default config;