import React from 'react'
import { OngoingInvoice } from '../models/shared/OngoingInvoice'
import { AdvancedButton } from './AdvancedButton'
import { useAuth } from 'reactfire';
import { finalizeInvoice } from '../utils/api';
import { toast } from 'react-toastify';

interface Props {
    invoice: OngoingInvoice
}

interface FinalizeCheckItem {
    checkId: string,
    label: string,
}

const FINALIZE_CHECKS: FinalizeCheckItem[] = [
    {
        checkId: 'fixed-items',
        label: 'Fixed items added',
    },
    {
        checkId: 'invoice-amount',
        label: 'Invoice amount as expected',
    },
]


function InvoiceFinalizeAction(props: Props) {
    const [processing, setProcessing] = React.useState(false);
    const { invoice } = props
    const { currentUser } = useAuth();
    const [checks, setChecks] = React.useState<Record<string, boolean>>({});


    const onFinalizeInvoice = async (invoice: OngoingInvoice) => {
        setProcessing(true);

        try {
            const token = await currentUser?.getIdToken();
            await finalizeInvoice(token, invoice.id || '');
            toast.success('Invoice send to Business Center');
        } catch (error) {
            console.error(error);
            toast.error('Failed to finalize invoice');
        }

        setProcessing(false);
    }

    if (invoice.finalizedAt) {
        return <div>
            <span className='me-2'>Finalized</span>
            <AdvancedButton processing={processing} onClick={() => onFinalizeInvoice(invoice)}
                variant='secondary' size="sm" icon='bi-arrow-clockwise' />

        </div>
    }

    const allChecksPassed = FINALIZE_CHECKS.every(check => checks[check.checkId]);
    return <div>
        {FINALIZE_CHECKS.map(check => {
            return <div key={check.checkId}>
                <input type="checkbox" checked={checks[check.checkId] || false} onChange={(e) => {
                    setChecks({
                        ...checks,
                        [check.checkId]: e.target.checked
                    });
                }} />
                <label className='ms-2'>{check.label}</label>
            </div>
        })}
        <AdvancedButton disabled={!allChecksPassed} icon='bi-send' processing={processing} onClick={() => onFinalizeInvoice(invoice)}>Finalize</AdvancedButton>
    </div>
}

export default InvoiceFinalizeAction
